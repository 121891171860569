<template>
  <Layout>
    <div class="containe  r mt-5 mb-5">
      <div class="row mt-5 mb-5 text-center">
        <h3>订单列表</h3>
      </div>
      <div class="row">
        <div class="course-cart py-120">
          <div class="container">
            <div class="course-cart-wrapper">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                  <tr>
                    <th>订单编号</th>
                    <th>订单名称</th>
                    <th>价格</th>
                    <th>下单时间</th>
                    <th>支付状态</th>
                    <th>操作</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in this.orders" :key="item.id">
                    <td>
                      <span>{{ item.id }}</span>
                    </td>

                    <td>
                      <span>{{ item.courseName }} * {{ item.courseDay }}天</span>
                    </td>

                    <td>
                      <span>￥{{ item.coursePrice }}</span>
                    </td>
                    <td>
                      <span>{{ item.createTime }}</span>
                    </td>
                    <td>
                      <span class="btn" :class="item.isPay ? 'btn-outline-success' :'btn-outline-danger'">{{
                          item.isPay ? '已支付' : '未支付'
                        }}</span>

                    </td>
                    <td>
                      <router-link :to=" { path: '/order/details',query: { id: item.id} } "
                                   class="link-primary">订单详情
                      </router-link>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@layouts/main'

export default {
  name: 'index',
  components: {Layout},
  data() {
    return {
      orders: []
    }
  },
  methods: {
    init() {
      const vm = this
      this.$axios.get('/Order/OrderList', {params: {id: this.$route.query.id}}).then((res) => {
        vm.orders = res.data.data
      })
    }
  },
  created() {
    this.init()
  }
}
</script>

<style scoped>

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

h1, h2, h3, h4, h5, h6 {
  color: #333;
  margin: 0px;
  font-weight: 600;
  font-family: 'Heebo', sans-serif;
  line-height: 1.2;
}

h5 {
  font-size: 18px;
}


p {
  margin: 0px;
}

.table {
  text-align: center;
}

.table > :not(:first-child) {
  border-top: none;
}

.table > :not(caption) > * > * {
  padding: 0.8rem 0.8rem;
}

/* course cart */

.course-cart thead tr {
  background: #00AF92;
  color: #fff;
}

.course-cart thead tr th {
  text-transform: capitalize;
}

.course-cart thead tr th,
.course-cart thead tr td {
  white-space: nowrap;
}

.cart-img {
  width: 100px;
}

.cart-img img {
  width: 100%;
  border-radius: 5px;
}

.course-cart tr td {
  color: #383838;
  vertical-align: middle;
  border-bottom: 1px solid #dee2e6;
  border-top: none;
  position: relative;
  padding: 20px;
  font-size: 16px;
}

.cart-qty {
  width: 150px;
}

.cart-qty button {
  color: #383838;
  border: 0;
  border-radius: 5px;
  padding: 4px 13px;
}

.cart-qty input {
  width: 50px;
  padding: 4px 13px;
  border-radius: 5px;
  border: none;
  background: #EFEFEF;
  text-align: center;
}

.cart-remove:hover {
  color: #da1d25;
}

.cart-footer {
  margin-top: 40px;
}

.cart-coupon {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 30px;
}

.cart-coupon .form-control {
  box-shadow: none;
  padding: 10px 14px;
  border-radius: 5px;
}

.cart-coupon .form-control:focus {
  border-color: #00AF92;
}

.coupon-btn {
  padding: 8px 14px;
  color: #fff;
  border: none;
  background: #00AF92;
  border-radius: 5px;
  transition: all .5s ease-in-out;
}

.coupon-btn:hover {
  background: #0A0F1F;
}

.cart-summary {
  float: right;
}

.cart-summary li {
  margin-bottom: 10px;
  width: 200px;
}

.cart-summary li span {
  float: right;
}

.cart-summary li strong {
  color: #030207;
}

.cart-total {
  border-top: 1px solid #eee;
}

.cart-total span {
  font-weight: bold;
  color: #da1d25;
}

.cart-summary .theme-btn {
  margin-right: 8px;
}

.theme-btn {
  background: #00AF92;
}

</style>
